:global(.ui.input).input input {
  width: 26px;
  font-size: 12px;
  padding: 2px 5px;
  margin-right: 2px;
  line-height: 15px;
  text-align: center;
}

:global(.ui.basic.button) {
  padding-top: 5px;
  padding-bottom: 5px;
}

:global(.ui.selection.dropdown).perPageDropdown {
  line-height: 15px;
  padding: 2px 5px;
  padding-right: 15px;
  min-height: auto;

  :global(.icon) {
    padding: 3px !important;
  }

  :global(.menu .item) {
    font-size: 12px;
    padding: 5px !important;
  }
}

.controlBox {
  float: right;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.countLabel {
  margin-right: 10px;
}

.resultsPerPageLabel {
  margin-left: 10px;
}

.form {
  display: inline;
}
