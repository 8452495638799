:global(.ui.modal .ui.loader).loader {
  color: rgba(0, 0, 0, 0.87);

  &::before {
    border-color: rgba(0, 0, 0, 0.1);
  }

  &::after {
    border-top-color: rgb(118, 118, 118);
    border-bottom-color: rgba(0, 0, 0, 0);
    border-left-color: rgba(0, 0, 0, 0);
    border-right-color: rgba(0, 0, 0, 0);
  }
}
