:global(.ui.dropdown .menu>.item).dropdownItem {
  :global(span) {
    font-size: 0.9em;
    vertical-align: top;
  }

  :global(.icon) {
    font-size: 1.2em;
  }

  :global(.arrow.left) {
    float: right;
    margin-left: 10px;
    margin-right: 0;
  }
}

:global(.ui.dropdown).filterIcon {
  position: absolute;
  top: 4px;
  right: 2px;
  cursor: pointer;
}
